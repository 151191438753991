import { Localized } from 'dg-web-shared/common/hooks/LanguageProvider';
import { ParkingaboLink } from '../../components/ParkinaboLink';
import { FeedbackPopup } from '../../components/FeedbackPopup';
import { useNavigate } from 'react-router-dom';
import { useParamOrNull } from 'dg-web-shared/lib/ReactRouterHelpers';

export function RegistrationLinkExpiredRoute() {
    const tenantId = useParamOrNull('tenantId');
    const navigate = useNavigate();
    return (
        <FeedbackPopup
            open
            color="error"
            onAbort={() => navigate(tenantId ? '..' : '/login')}
            abortLabel={
                <Localized de="Schliessen" fr="Fermer" it="Chiudi" en="Close" />
            }
            title={
                <Localized
                    de="Bestätigung fehlgeschlagen"
                    fr="Confirmation échouée"
                    it="Conferma non riuscita"
                    en="Confirmation failed"
                />
            }
        >
            <Localized
                de="Dieser Link ist nicht mehr gültig. Sie konnen "
                fr="Ce lien n'est plus valide. Vous pouvez demander "
                it="Questo link non è più valido. Può richiedere "
                en="This link is no longer valid. You can request "
            />
            <ParkingaboLink
                to={tenantId ? `/${tenantId}/settings/user/email` : '/register'}
                color="error"
            >
                <Localized
                    de="hier ein neuer Link"
                    fr="ici un nouveau lien"
                    it="qui un nuovo link"
                    en="here a new link"
                />
            </ParkingaboLink>
            <Localized de=" anfordern." fr="." it="." en="." />
        </FeedbackPopup>
    );
}
